.navbar-container {
  display: flex;
  min-width: 375px;
  width: 100%;
  position: relative;
  max-height: fit-content;
  justify-content: center;
}

.navbar-container nav ul {
  display: inline-flex;
  flex-flow: row nowrap;
  width: fit-content;
  justify-content: center;
  -webkit-justify-content: center;
}

.navbar-container nav a {
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--darkGray);
  background-image: linear-gradient(rgb(255, 184, 30), rgb(255, 188, 30));
  background-size: 0 1px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
  font-weight: 500;
  font-size: 16px;
  padding: 0 10px 0 10px;
}

.navbar-container nav a.active:hover,
.navbar-container nav a:hover {
  background-size: 100% 1px, auto;
  color: var(--shadowGray);
}

.navbar-container nav a.active {
  color: var(--darkOrange);
  font-weight: 600;
  transition: all 0.5s ease-out;
}
