* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Raleway";
  font-size: 12px;
  font-weight: 300;
  user-select: none;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  user-select: none;
  /*object-fit: cover;*/
}

h1,
h3 {
  font-family: "Raleway";
  font-size: 2rem;
  letter-spacing: 0.7px;
  color: var(--lightGray);
}

h3 {
  font-size: 1.4rem;
  font-family: "Raleway";
  font-weight: 100px;
  letter-spacing: 0.7px;
  color: var(--lightGray);
}

/*
h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h4 {
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
*/

a,
a.active,
a.link,
a.hover {
  all: unset;
  cursor: pointer;
}

nav,
li,
ul {
  text-transform: uppercase;
}

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
}

.container {
  display: flex;
  flex-flow: column;
}

.hide,
.hidden,
.off,
.invisible {
  display: none;
  visibility: hidden;
}

.show,
.on,
.visible {
  display: unset;
  visibility: visible;
}

.under-construction {
  position: relative;
  height: 100%;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  font-size: 40px;
  color: var(--blue);
  margin: 0;
  padding: 10px;
  font-weight: 500;
}

.under-construction p {
  font-size: 20px;
  color: var(--orange);
  padding: 10px;
  font-weight: 400;
}
