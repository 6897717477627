footer {
  z-index: 10;
  display: flex;
  flex-flow: row;
  margin: auto;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 1px solid rgba(255, 132, 0, 0.3);
}

@media screen and (max-width: 600px) {
  footer {
    border-top: 3px solid rgba(255, 132, 0, 1);
  }
}
