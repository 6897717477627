.navbar-wrapper {
  position: relative;
}

/* SUBMENU CONTAINER DROPDOWN */
.responsive-menu {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  position: absolute;
  width: fit-content;
  height: fit-content;
  max-height: 0;
  width: 169px;
  top: 33px;
  left: 100px;
  z-index: 3;
  transition: all 0.5s ease-in-out;
  border-left: 3px solid rgba(255, 132, 0, 1);
  border-right: 3px solid rgba(255, 132, 0, 1);
}

/* DROP DOWN ACTIVE */
.responsive-menu.on {
  border-left: 3px solid rgba(255, 132, 0, 1);
  border-right: 3px solid rgba(255, 132, 0, 1);
  display: flex;
  visibility: inherit;
  max-height: 300px;
  height: fit-content;
  /*box-shadow: var(--box-shadow-small);*/
  box-shadow: var(--box-shadow);
}

/* DROP DOWN CONTENT BOX */
.responsive-container {
  background: rgba(30, 30, 30, 0.9);
  height: fit-content;
}

.responsive-container li {
  padding: 4px;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: var(--orange);
  padding: 8px 5px 8px 5px;
}

.responsive-container li:hover {
  background-color: rgba(255, 132, 0, 1);
  color: black;
}

/* ACTIVE / INACTIVE */
.responsive-active {
  display: none;
  visibility: hidden;
}
.responsive-hidden {
  display: flex;
  visibility: inherit;
}
.responsive-active .material-icons.off {
  transition: all 0.5s ease-in-out;
  transform: rotate(180deg);
}

.responsive-active .material-icons.open {
  transition: all 0.5s ease-in-out;
  transform: rotate(0deg);
}

@media screen and (max-width: 768px) {
  .off {
    display: flex;
    visibility: inherit;
  }
  .responsive-active {
    display: flex;
    visibility: inherit;
  }
  .responsive-hidden {
    display: none;
    visibility: hidden;
  }
  .navbar-container nav,
  .responsive-link,
  .navbar-container ul,
  .navbar-container a {
    margin: 0;
    padding: 0 !important;
    font-size: 16px !important;
  }
  .navbar-container li {
    padding: 8px !important;
    line-height: 30px;
  }
}
