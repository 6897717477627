.logo {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  text-align: center;
  -webkit-text-align: center;
  align-items: baseline;
  -webkit-align-items: baseline;
  /*background: no-repeat url("../../assets/logo-zieger.png");*/
  padding: 0px 10px 5px 10px;
}

.logo img {
  width: 200px;
  height: 73px;
  /*background: no-repeat url("../../assets/logo-zieger.png");*/
}

@media screen and (max-width: 600px) {
  .logo {
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0;
    margin: 0;
    padding-top: 10px;
    align-items: top;
    -webkit-align-items: top;
  }
  .logo img {
    width: 120px;
    height: 38px;
    margin-bottom: 0px;
  }
}
