.diverse-container {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  background: no-repeat url("../../assets/t01-g.webp");
  -webkit-animation: slide-in-fwd-center 0.7s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background-size: cover;
  background-position: 1% 10%;
  height: 100%;
  min-height: calc(100vh - (60px + 170px));
  padding: 100px;
  padding-top: 70px;
}

.diverse {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: fit-content;
  height: 100%;
  text-align: center;
  padding: 20px;
  min-width: none;
  background: rgba(30, 30, 30, 0.9);
  border-radius: 3px;
  box-shadow: var(--box-shadow);
}

.diverse-top {
  position: relative;
  justify-content: left;
  text-align: left;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.diverse-top-left {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  flex-grow: 1;
}

.diverse-top-left h1 {
  font-size: 30px;
  font-weight: 600;
  color: var(--blue);
  padding: 25px 20px 10px 20px;
  margin-top: 0;
  -webkit-animation: fade-in-right 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.diverse-top-left h3 {
  border-left: 5px solid rgba(255, 132, 0, 1);
  font-weight: 600;
  font-size: 16px;
  padding: 0px;
  margin-left: 20px;
  padding: 3px 15px 3px 15px;
  white-space: pre-line;
}
.diverse-top-left .two {
  /*color: var(--blue);*/
  color: var(--lightOrange);
  padding-top: 15px;
  padding-left: 20px;
  font-weight: 600;
  border-left: 0px solid rgba(255, 132, 0, 1);
}

.diverse-top-left p {
  font-weight: 400;
  font-size: 16px;
  padding: 20px 15px 3px 1px;
  margin-left: 30px;
  color: var(--lightGray);
}

.diverse-bottom-left {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: fit-content;
  margin: 20px 10px 50px 10px;
  justify-content: space-evenly;
}

.diverse figure {
  display: flex;
  margin: auto;
  position: relative;
  background: rgba(30, 30, 30, 0.6);
  box-shadow: var(--box-shadow);
  border-radius: 3px;
  margin: 20px;
  margin-bottom: 20px;
}
.diverse figure img {
  object-fit: cover;
  width: 350px;
}

.diverse figure.i01 {
  -webkit-animation: slide-in-right 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.diverse figure.i02 {
  -webkit-animation: slide-in-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.diverse figure.i03 {
  -webkit-animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.diverse figure.i04 {
  -webkit-animation: slide-in-right 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.diverse figure.i05 {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.diverse.facade {
  margin-top: 70px;
}

.diverse.facade figure img {
  object-fit: cover;
}

/*
.diverse figure img:hover {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
*/
@media screen and (max-width: 430px) {
  .diverse-container {
    padding: 10px;
    padding-top: 70px;
    padding-bottom: 60px;
  }

  .diverse {
    margin: auto;
    max-width: 100%;
    width: 350px;
    padding: 10px;
    min-width: 300px;
  }

  .diverse figure {
    margin: 20px;
    margin-bottom: 20px;
  }
  .diverse figure img {
    object-fit: cover;
    width: 300px;
  }
}

.new-line {
  white-space: pre-line;
}
