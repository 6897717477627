header {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 125px;
}

@media screen and (max-width: 600px) {
  header {
    height: fit-content;
    border-bottom: 3px solid rgba(255, 132, 0, 1);
  }
}
