.second-navbar-container {
  display: none;
  visibility: hidden;
  min-width: 375px;
  width: 100%;
  max-height: fit-content;
  background-color: rgba(0, 0, 0, 0.7);
  background-color: var(--darkOrange);
  -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  justify-content: center;
  -webkit-justify-content: center;
}

/* Responsive Mobile */
@media screen and (min-width: 768px) {
  .second-navbar-container {
    display: flex;
    visibility: inherit;
  }
}

.second-navbar-container nav {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.second-navbar-container nav ul {
  flex-flow: row wrap;
  display: flex;
  width: 100%;
  justify-content: center;
  -webkit-justify-content: center;
}

.second-navbar-container nav a {
  display: flex;
  width: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  color: white;
  background-image: linear-gradient(rgb(255, 184, 30), rgb(255, 188, 30));
  background-size: 0 1px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
  padding: 8px 15px 8px 15px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.3px;
  text-transform: initial;
}

.second-navbar-container nav a.m1 {
  -webkit-animation: slide-in-left 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.second-navbar-container nav .m2 {
  -webkit-animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.second-navbar-container nav .m3 {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.second-navbar-container nav .m4 {
  -webkit-animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  min-width: fit-content;
}

.second-navbar-container nav a:hover {
}

.second-navbar-container nav a:hover,
.second-navbar-container nav a.active {
  color: var(--black);
}

/* Responsive Mobile */
@media screen and (max-width: 600px) {
}
