:root {
  /* COLOR */
  --shadowGray: rgba(100, 102, 103, 0.7);
  --lightGray: #dcdcdc;
  --darkGray: #646667;
  --white: #ffffff;
  --black: #000000;
  --lightRed: #fb9696;
  --hoverbg: #ededed;
  --orange: #ff8e56;
  --secondOrange: #ffb28b;
  /*--gold: rgb(255, 184, 30);*/
  --hardOrange: rgba(255, 132, 0, 1);
  --gold: #ec5b1cf2;
  --darkOrange: #ec5b1cf2;
  --lightOrange: #f29667;
  --strongOrange: #ff6600;
  --lightGreen: #6b881c;
  --darkGreen: #507319;
  --darkBlue: #0c2a6a;
  --lightBlue: #1e499b;
  --blueCiel: #1e499b;
  --blue: #47c2ff;
  /*--blue: white;*/
  /*--blueCiel: #0087ca;*/
  --mainGray: #757575;
  --fifthGray: rgb(196, 196, 196);
  --thirdGray: #f5f5f5;
  --secondGray: #333333;
  --forthGray: #aaaaaa;

  /* SHADOW / GRADIENT */
  --text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.13);
  --font-shadow: 1px 1px 1px rgba(0, 0, 0, 0.09);
  --font-shadow-off: 2px 2px 2px rgba(0, 0, 0, 0);
  --box-shadow-light: 3px 4px 9px 0px rgba(0, 0, 0, 0.3);
  --button-shadow-light: 4px 1px 8px 1px rgba(0, 0, 0, 0.1);
  --box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.6);
  --box-shadow-small: 3px 3px 5px 2px rgba(0, 0, 0, 0.6);
  --box-shadow-hover: 0px 5px 8px 1px rgba(0, 0, 0, 0.3);
  --box-shadow-right: 3px 3px 16px -1px rgba(0, 0, 0, 0.21);
  --box-shadow-up: inset 0px 3px 8px -3px rgba(0, 0, 0, 0.28);
  --gradient: linear-gradient(
    0deg,
    rgba(255, 96, 96, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );

  /* FONT */
  --shrik: "Shrikhand", cursive;
  --fontawesome: "Font Awesome 5 Free";
  --robo: "Roboto", sans-serif;
  --robo-c: "Roboto Condensed", sans-serif;
  --material: "Material Icons";
  --inter: "Inter";
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-12 21:41:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
