.contact {
  display: flex;
  flex-flow: row wrap;
  background: no-repeat url("../../assets/contact2.webp");
  background-size: cover;
  background-position: 5% 5%;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - (60px + 170px));
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px;
  padding-bottom: 0px;
  margin: 0;
  -webkit-animation: slide-in-fwd-center 0.7s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* Main Container */
.contact-container {
  position: relative;
  background: rgba(30, 30, 30, 0.9);
  display: flex;
  flex-flow: column;
  min-width: 320px;
  width: 100%;
  max-width: 90%;
  margin: auto;
  margin-top: 70px;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  border-radius: 3px;
  box-shadow: var(--box-shadow);
}

/* Top Container */
.contact-top-container {
  display: flex;
  flex-flow: row wrap;
}

/* Top Left Container */
.contact-header {
  display: flex;
  flex-flow: column;
  max-width: 70%;
  margin: auto;
  padding: 50px;
  margin-top: 0;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .contact-container {
    padding: 0px;
  }

  .contact {
    padding: 20px 0 40px 0;
  }

  .contact-header {
    max-width: 100%;
    width: 100%;
  }
}

.contact-header h1,
.contact-header h3,
.contact-header p,
.contact-title {
  display: flex;
  padding: 0px 0px 5px 0px;
  line-height: 25px;
  font-weight: 100px;
}

.contact-header h1,
.contact-title {
  color: var(--blue);
  font-size: 30px;
  font-weight: 600;
  padding: 25px 0px 20px 0px;
  margin-top: 0;
}

.contact-header h3,
.contact-header p {
  font-family: "Raleway";
  font-size: 16px;
  letter-spacing: 0.3px;
  text-align: left;
  padding: 3px 0px 3px 15px;
  line-height: 25px;
  font-weight: 300;
  color: var(--lightGray);
  border-left: 5px solid rgba(255, 132, 0, 1);
  margin-left: 5px;
}

/* Top Right Container */
.contact-form {
  display: flex;
  flex-flow: column;
  max-width: 100%;
  flex-grow: 1;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-top: 0;
  -webkit-animation: scale-in-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.5s both;
  animation: scale-in-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s
    both;
}

form {
  width: 100%;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  outline: none;
  border: 1px solid var(--darkOrange);
  color: white;
  background-color: var(--darkOrange);
  display: flex;
  padding: 7px;
  border-radius: 2px;
  font-size: 14px;
  margin: 10px 0 10px 0;
  box-shadow: var(--box-shadow);
}

.contact-form textarea {
  height: 125px;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

textarea:valid,
input:valid {
  background-color: var(--darkOrange);
}

textarea:invalid,
input:invalid {
  background-color: var(--lightRed);
  border-color: var(--lightRed);
}

.contact-form button {
  outline: none;
  background-color: var(--blueCiel);
  border: 1px solid var(--blueCiel);
  color: white;
  display: flex;
  width: 100px;
  text-align: center;
  justify-content: center;
  border-radius: 2px;
  margin: auto;
  margin-right: 0;
  font-size: 12px;
  padding: 10px;
  font-weight: 400;
  letter-spacing: 0.7px;
  cursor: pointer;
  box-shadow: var(--box-shadow);
}

.contact-form button:hover {
  color: white;
  border: 1px solid var(--darkOrange);
  background-color: var(--darkOrange);
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
input:-moz-placeholder,
input::placeholder .first-name::after {
  font-family: "Material Icons" !important;
  content: "\e7fd" !important;
  color: #000 !important;
}

.last-name {
}

.email {
  font-weight: 500 !important;
  font-size: 16px !important;
  padding-left: 10px;
  cursor: pointer;
}
span.email:hover {
  color: var(--orange) !important;
}

.subject {
}

.message {
}

a .phone-icon:hover {
  color: var(--orange) !important;
}

.error-messages.off {
  display: none;
  visibility: hidden;
}

.error-messages .success {
  display: none;
  visibility: hidden;
}

.error-messages .error {
  display: flex;
  visibility: visible;
  color: var(--lightRed);
  background-color: rgb(0, 0, 0);
  font-weight: 500;
  letter-spacing: 1.2px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

/* Middle Line *
.contact-middle {
  border-top: 1px solid var(--darkOrange);
  margin: 30px 4% 0 2%;
}

/* Bottom Container */
.contact-bottom {
  margin: 0 10px 0px 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.contact-bottom-left {
  display: flex;
  flex-flow: column;
  max-width: 100%;
}

.contact-bottom-left h1,
.contact-bottom-left h3 {
}

.contact-bottom-left h1 {
}

.contact-bottom-left h3,
.contact-bottom-left p {
  padding: 0px 0 0 20px;
  font-weight: 300;
}

.contact-bottom-left .zone {
  white-space: pre-line;
}

.contact-bottom-left .phone-icon {
  font-family: "Poppins";
  letter-spacing: 1px;
  color: var(--blue);
  font-weight: 500;
  font-size: 18px;
  padding-top: 15px;
}

.phone-icon::before {
  font-family: "Material Icons";
  content: "\e0b0";
  font-weight: 200;
  line-height: 30px;
  font-size: 25px;
  padding: 0 10px 0 0;
}

/* SOCIAL MEDIA NETWORKS*/
.contact-media {
  display: flex;
  flex-flow: row wrap;
  color: white;
  margin: auto;
  height: 100%;
  max-width: 100%;
}

.media-sc,
.media-tt,
.media-fb,
.media-in,
.media-ig,
.media-tw,
.media-wa,
.media-yt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  filter: invert();
  margin: 1vw;
  margin-top: 20px;
}

.media-fb {
  background-image: url("../../assets/logo-facebook.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: slide-in-left 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.media-wa {
  background-image: url("../../assets/logo-whatsapp.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.media-ig {
  background-image: url("../../assets/logo-twitter.svg");
  background-repeat: no-repeat;
  background-size: 50% cover;
  -webkit-animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.media-tw {
  background-image: url("../../assets/logo-instagram.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: slide-in-left 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.media-in {
  background-image: url("../../assets/logo-linkedin.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.media-yt {
  background-image: url("../../assets/logo-youtube.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: slide-in-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.media-sc {
  background-image: url("../../assets/logo-snapchat.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.media-tt {
  background-image: url("../../assets/logo-tiktok.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: slide-in-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*.media-fb:hover,
.media-in:hover,
.media-ig:hover,
.media-tw:hover,
.media-yt:hover, */
.media-fb:hover,
.media-tt:hover,
.media-sc:hover,
.media-wa:hover {
  cursor: pointer;
  /* background-color: var(--darkOrange);*/
  filter: invert(45%);
}

.none {
  padding: 0 !important;
  margin: 0 !important;
}

.error-message {
  color: var(--lightRed);
  padding: 0px 0 0 0 !important;
  letter-spacing: 0.7px;
}

.text .error-message,
.message-sent {
  padding: 0px 0 5px 0 !important;
  letter-spacing: 0.7px;
  font-size: 13px;
}
