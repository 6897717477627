.services-container {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  background: no-repeat url("../../assets/p01-g.webp");
  -webkit-animation: slide-in-fwd-center 0.7s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background-size: cover;
  background-position: 1% 10%;
  height: 100%;
  min-height: calc(100vh - (60px + 170px));
}

.services {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 100px;
  padding-top: 70px;
  min-width: none;
}

.services-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: fit-content;
  height: fit-content;
  text-align: center;
  padding: 10px;
}

.services figure {
  position: relative;
  width: 300px;
  height: 350px;
  background: rgba(30, 30, 30, 0.6);
  box-shadow: var(--box-shadow);
  border-radius: 3px;
  margin-top: 20px;
}

figure.nettoyage-img {
  background: url(../../assets/t01-g.webp);
  background-size: cover;
  background-position: 20% 20%;
}

figure.peinture-img {
  background: url(../../assets/f01-g.webp);
  background-position: 60% 70%;
  background-size: cover;
}

figure.ramonage-img {
  background: url(../../assets/r01.webp);
  background-size: cover;
  background-position: 60% 60%;
}

figure.couverture-img {
  background: url(../../assets/c03.webp);
  background-size: cover;
  background-position: 10% 10%;
}

figure.service-image {
  background: url(../../assets/d01-g.webp);
  background-size: cover;
  background-position: 50% 50%;
}

.services figure h1 {
  z-index: 2;
  align-items: center;
  margin: auto;
  padding: 0;
  text-align: left;
  font-size: 30px;
  padding: 40px;
  font-weight: 600;
  background: rgba(30, 30, 30, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  min-width: 300px;
  transition: all 0.5s ease-in-out;
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  border-radius: 3px;
}

.services figure h1:hover {
  z-index: 2;
  background: rgba(30, 30, 30, 0.7);
  transition: all 0.5s ease-in-out;
}

figcaption {
  display: flex;
  z-index: 1;
  position: absolute;
  top: 0;
  color: white;
  margin: auto;
  width: 100%;
  justify-content: center;
}

figcaption button {
  all: unset;
  position: absolute;
  top: 300px;
  margin: auto;
  border-radius: 2px;
  font-size: 13px;
  padding: 10px 20px 10px 20px;
  font-weight: 400;
  letter-spacing: 0.7px;
  cursor: pointer;
  background-color: var(--darkOrange);
  box-shadow: var(--box-shadow);
  display: none;
}

figcaption button:hover {
  background-color: var(--blueCiel);
}

.services .m1 {
  -webkit-animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.services .m2 {
  -webkit-animation: slide-in-left 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.services .m3 {
  -webkit-animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.services .m4 {
  -webkit-animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  min-width: fit-content;
}

.services-top {
  display: flex;
  flex-flow: column;
  justify-content: left;
  text-align: left;
  width: 100%;
  box-shadow: var(--box-shadow);
  background: rgba(30, 30, 30, 0.9);
  border-radius: 3px;
  padding-bottom: 20px;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.services-top h1 {
  font-size: 30px;
  font-weight: 600;
  color: var(--blue);
  padding: 25px 20px 10px 20px;
  margin-top: 0;
  -webkit-animation: fade-in-right 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.services-top h3 {
  border-left: 5px solid rgba(255, 132, 0, 1);
  font-weight: 500;
  padding: 0px;
  margin-left: 20px;
  padding: 3px 0px 3px 15px;
}

@media screen and (min-width: 430px) and (max-width: 1023px) {
  .services-container {
    padding: 10px;
    padding-top: 70px;
  }

  .services {
    margin: auto;
    max-width: 100%;
    width: 900px;
    padding: 10px;
    min-width: 300px;
  }

  .services h3 {
    padding: 20px;
  }

  .services figure {
    margin: 20px;
    margin-bottom: 20px;
  }
  .services figure img {
    object-fit: cover;
    width: 300px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .services-container {
    padding: 10px;
    padding-top: 70px;
  }

  .services {
    margin: auto;
    max-width: 100%;
    width: 1200px;
    padding: 10px;
    min-width: 300px;
  }

  .services figure {
    margin: 20px;
    margin-bottom: 20px;
  }
  .services figure img {
    object-fit: cover;
    width: 300px;
  }
}
